import SparrowGame from "./Components/SparrowGame";
import Footer from "./Components/footer/Footer";
import "./index.css"

function App() {
  return (
    <div className="App">
      <SparrowGame />
       
    </div>
  );
}

export default App;
