import React from "react";

function Footer() {
  return (
    <div className="flex flex-col lg:px-20  px-5">
      <div
        className="lg:flex py-3 justify-evenly   gap-6
       lg:gap-12"
      >
        <div className="flex gap-8">
          <div>Sparrow Game 2024</div>
        </div>
        <div className="flex "></div>
      </div>
      <div>
        <span>
          © 1994-2024 Sparrow Game – Best online Gaming Platform. Please note
          that this website is exclusively intended for users who are 18 years
          of age or older. If you are below the age of 18, we kindly request you
          to refrain from using this website. Age restrictions are in place to
          ensure a safe and responsible gaming environment.If you belongs to
          these states Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim, and
          Telangana, we kindly request you to refrain from using this website.
        </span>
      </div>
    </div>
  );
}

export default Footer;
